import { Locales } from 'lib/i18n/constants';

const languageMap: Record<Locales, string> = {
  [Locales.DE]: 'de',
  [Locales.FR]: 'fr',
  [Locales.UK]: 'en-GB',
  [Locales.IE]: 'en-GB', // no support for Ireland en-IE, using en-GB
};

/**
 * Our locales are incompatible with a list of languages supported by Google
 * Places API, therefore we need to convert them first. The `'en'` value is not
 * related to BB locales but the one used by the Google API.
 *
 * @see https://developers.google.com/maps/faq#languagesupport
 */
export const getGoogleAPILanguage = (locale?: Locales) => languageMap[locale as Locales] || 'en';
