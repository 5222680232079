import { SentryIgnoredError } from './sentry-ignored-error';

export const commonConfig = {
  /**
   * We use "production" as catch-all environment for
   * all the errors happening in the production Firebase
   * project (atm "bridebookhq").
   */
  ...(process.env.NEXT_PUBLIC_DATABASE === 'production' && {
    environment: 'production',
  }),

  /**
   * But you can override the environment by adding a
   * "NEXT_PUBLIC_SENTRY_ENVIRONMENT" environment variable
   * in either Heroku or Vercel
   */
  ...(process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT && {
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  }),

  /**
   * This needs to be passed as env in the corresponding
   * next.config.js for the running application.
   */
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,

  /**
   * 1% of production request are gonna be sampled
   * and no sampling at all in dev/vercel-preview since
   * the amount transactions for sampling is limited
   **/

  tracesSampleRate: process.env.NEXT_PUBLIC_DATABASE === 'production' ? 0.01 : 0.0,

  /**
   * Disabled by default, enable per sentry.{client,server,edge}.config.ts in
   * corresponding app directory in development if you need to debug Sentry.
   **/
  debug: false,

  /**
   * Allows to throw some errors without sending them to Sentry.
   */
  ignoreErrors: [SentryIgnoredError],
};
