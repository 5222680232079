export const imgixBaseURL = 'https://images.bridebook.com';

export default function buildImgixUrl({
  public_id,
  ext,
}: {
  public_id: string;
  ext: string;
}): string {
  return `${imgixBaseURL}/${public_id}.${ext}`;
}
