import { IRenderer } from 'fela';
import { setAutoFreeze } from 'immer';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { PrefetchOptions, Url } from 'next/dist/shared/lib/router/router';
import { addLocale } from 'next/dist/shared/lib/router/utils/add-locale';
import dynamic from 'next/dynamic';
import { Router } from 'next/router';
import React from 'react';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { GoogleMapsScriptContextProvider } from 'app-shared/lib/search/utils/with-google-maps-api';
import { AppState } from 'lib/types';
// In order to make it work it has to be the first import
import '../styles/appsflyer.css';
import '../styles/global.css';
import '../styles/react-spring-bottom-sheet.css';
import '../styles/swipe-to-action.css';

// With immer 8.0.0 release, "freeze" happens always by default
// (this means not only in dev but also in production)
// However, in production build this results in some problems:
//   1. Login in with Google / Continue with Google button with broken style
//   2. Pages of footer links with broken style (examples: Cookie Policy, Privacy Policy, etc)
//   3. 404 page for a fraction of second when searching for venues. Steps to reproduce:
//        - search for venues in a give city (for instance London)
//        - click in one venue to open it
//        - click on the next button to move to the next venue
//        - 404 page will be displayed for a fraction of second
// Setting the old "freeze" behavior while these issues are not fixed
// https://github.com/immerjs/immer/pull/702
setAutoFreeze(process.env.NODE_ENV !== 'production');

if (process.browser) {
  require('intersection-observer');
}

const AppFull = dynamic(() => import('./_app-full'));
const AppIframe = dynamic(() => import('./_app-iframe'));
const AppBasic = dynamic(() => import('./_app-basic'));

export type IAppProps<P = Record<string, any>> = AppProps<P> & {
  Component: NextPage<P>;
  renderer?: IRenderer;
  initialDevice: AppState['device'];
};

export enum AppType {
  Basic = 'basic',
  Full = 'full',
  Iframe = 'iframe',
}

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

const original = {
  prefetch: Router.prototype.prefetch,
  push: Router.prototype.push,
  replace: Router.prototype.replace,
};

Object.defineProperty(Router.prototype, 'locale', {
  get() {
    return this.query?.market;
  },
  configurable: false,
  enumerable: true,
});

Router.prototype.prefetch = function (
  url: string,
  asPath?: string,
  options?: PrefetchOptions,
): ReturnType<typeof original.prefetch> {
  /**
   * Passthrough to original function if either locale false
   * or if we don't have a market parameter set (e.g. for
   * error pages, this can be internally used by Next)
   **/
  if (options?.locale === false || this.query?.market == null) {
    return original.prefetch.apply(this, [url, asPath, options]);
  }

  const market = gazetteer.getMarketByURL(this.query.market as string);

  let newAs = asPath;
  let newUrl = url;

  if (typeof asPath === 'string') {
    newAs = addLocale(asPath, market?.prefix);
  }

  if (typeof url === 'string') {
    newUrl = addLocale(url, market?.prefix);
  }

  return original.prefetch.apply(this, [newUrl, newAs, options]);
};

Router.prototype.push = function (
  url: Url,
  as?: Url,
  options?: TransitionOptions,
): ReturnType<typeof original.push> {
  /**
   * Passthrough to original function if either locale false
   * or if we don't have a market parameter set (e.g. for
   * error pages, this can be internally used by Next)
   **/
  if (options?.locale === false || this.query?.market == null) {
    return original.push.apply(this, [url, as, options]);
  }

  const market = gazetteer.getMarketByURL(this.query.market as string);

  let newAs = as;
  let newUrl = url;

  /**
   * `as` and `url` can be either strings or UrlObjects, where
   * the latter shares properties with URL object in the browser.
   *
   * We need to handle both cases.
   */
  if (typeof as === 'string') {
    newAs = addLocale(as, market?.prefix);
  } else if (typeof as === 'object' && as?.pathname) {
    newAs = {
      ...as,
      pathname: addLocale(as.pathname, market?.prefix),
    };
  }

  if (typeof url === 'string') {
    newUrl = addLocale(url, market?.prefix);
  } else if (typeof url === 'object' && url?.pathname) {
    newUrl = {
      ...url,
      pathname: addLocale(url.pathname, market?.prefix),
    };
  }

  return original.push.apply(this, [newUrl, newAs, options]);
};

Router.prototype.replace = function (
  url: Url,
  as?: Url,
  options?: TransitionOptions,
): ReturnType<typeof original.replace> {
  /**
   * Passthrough to original function if either locale false
   * or if we don't have a market parameter set (e.g. for
   * error pages, this can be internally used by Next)
   **/
  if (options?.locale === false || this.query?.market == null) {
    return original.replace.apply(this, [url, as, options]);
  }

  const market = gazetteer.getMarketByURL(this.query.market as string);

  let newAs = as;
  let newUrl = url;

  /**
   * `as` and `url` can be either strings or UrlObjects, where
   * the latter shares properties with URL object in the browser.
   *
   * We need to handle both cases.
   */
  if (typeof as === 'string') {
    newAs = addLocale(as, market?.prefix);
  } else if (typeof as === 'object' && as?.pathname) {
    newAs = {
      ...as,
      pathname: addLocale(as.pathname, market?.prefix),
    };
  }

  if (typeof url === 'string') {
    newUrl = addLocale(url, market?.prefix);
  } else if (typeof url === 'object' && url?.pathname) {
    newUrl = {
      ...url,
      pathname: addLocale(url.pathname, market?.prefix),
    };
  }

  return original.replace.apply(this, [newUrl, newAs, options]);
};

const MyApp = (props: IAppProps) => (
  <>
    <GoogleMapsScriptContextProvider>
      {props.Component.AppType === AppType.Basic ? (
        <AppBasic {...props} />
      ) : props.Component.AppType === AppType.Iframe ? (
        <AppIframe {...props} />
      ) : (
        <AppFull {...props} />
      )}
    </GoogleMapsScriptContextProvider>
  </>
);
export default MyApp;
