import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { ConsumerProps, FC, PropsWithChildren, createContext, useState } from 'react';
import { getGoogleAPILanguage } from 'app-shared/lib/search/utils/get-google-api-language';
import { Locales } from 'lib/i18n/constants';

interface IGoogleMapsScriptContext {
  loaded: boolean;
  setLoaded: (loaded: boolean) => void;
}

const GoogleMapsScriptContext = createContext<IGoogleMapsScriptContext | null>(null);

export const GoogleMapsScriptContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <GoogleMapsScriptContext.Provider
      value={{
        loaded,
        setLoaded,
      }}>
      {children}
    </GoogleMapsScriptContext.Provider>
  );
};

const GoogleMapsScriptContextConsumer: FC<ConsumerProps<IGoogleMapsScriptContext>> = ({
  children,
}) => (
  <GoogleMapsScriptContext.Consumer>
    {(context) => {
      if (context === null) {
        throw new Error(
          'GoogleMapsScriptContextConsumer must be used within a GoogleMapsScriptContextProvider',
        );
      }
      return children(context);
    }}
  </GoogleMapsScriptContext.Consumer>
);

const LoadGoogleMapsAPI = ({ children }: PropsWithChildren) => {
  // Use the market locale for search suggestions
  const { locale } = useRouter();
  const googleAPILanguage = getGoogleAPILanguage(locale as Locales);

  return (
    <GoogleMapsScriptContextConsumer>
      {({ loaded, setLoaded }) => {
        // Attach global callback for Google Maps:
        // https://developers.google.com/maps/documentation/javascript/url-params#required_parameters
        if (typeof window !== 'undefined') {
          window.initBBMap = () => setLoaded(true);
        }

        return (
          <>
            <Script
              async
              id="google_maps_script"
              strategy="lazyOnload"
              src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAyAZfZjdJZjsxqeef9Bu4B3MpFTUkSShQ&v=3.exp&libraries=places,geometry,marker&language=${googleAPILanguage}&loading=async&callback=initBBMap`}
            />
            {loaded && children}
          </>
        );
      }}
    </GoogleMapsScriptContextConsumer>
  );
};

export const withGoogleMapsApi =
  <T extends object>(Component: React.FC<T>): React.FC<T> =>
  (props: T) =>
    (
      <LoadGoogleMapsAPI>
        <Component {...props} />
      </LoadGoogleMapsAPI>
    );
