export const env = {
  API_ZENDESK_WIDGET: process.env.NEXT_PUBLIC_API_ZENDESK_WIDGET,
  BB_S3_IMAGE_REGION: process.env.BB_S3_IMAGE_REGION || 'us-west-1',
  CMS_URL: process.env.NEXT_PUBLIC_CMS_URL,
  COUPLESIDE_URL: process.env.NEXT_PUBLIC_COUPLESIDE_URL,
  CUSTOMER_IO_API_KEY: process.env.CUSTOMER_IO_API_KEY,
  CUSTOMER_IO_SITE_ID: process.env.CUSTOMER_IO_SITE_ID,
  ELASTIC_ARTICLES: process.env.ELASTIC_ARTICLES,
  FIREBASE: { databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL },
  IS_PRODUCTION_BUILD: process.env.NODE_ENV === 'production',
  LIVE: process.env.NEXT_PUBLIC_DATABASE === 'production',
  GCP_LIVE: process.env.GCLOUD_PROJECT === 'bridebookhq',
  MONITORING_TOKEN: process.env.MONITORING_TOKEN || '',
  REDIS_HOST: process.env.REDIS_HOST,
  REDIS_PASS: process.env.REDIS_PASS || '',
  REDIS_PORT: process.env.REDIS_PORT,
  REDIS_URL: process.env.REDIS_URL || '',
  REDIS_USER: process.env.REDIS_USER,
  S3_API_IMAGE_ACCESS_KEY: process.env.S3_API_IMAGE_ACCESS_KEY,
  S3_API_IMAGE_SECRET_KEY: process.env.S3_API_IMAGE_SECRET_KEY,
  SENTRY: process.env.NEXT_PUBLIC_SENTRY || process.env.NEXT_PUBLIC_SENTRY_DSN,
  SKIP_REDIS: process.env.SKIP_REDIS !== undefined,
  TALKJS_APP_ID: process.env.NEXT_PUBLIC_TALKJS_APP_ID,
  TALKJS_SECRET_KEY: process.env.TALKJS_SECRET_KEY,
  MIN_ARTICLES_COUNT: process.env.MIN_ARTICLES_COUNT,
};
