import Locale from 'date-fns';
import { de, enGB, enIE, fr } from 'date-fns/locale';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

export enum CMSLocales {
  DE = 'de',
  GB = 'gb',
  FR = 'fr',
  PL = 'pl',
  US = 'us',
  IE = 'ie',
}

/**
 * These locale identifiers are used only for routing, eg. bridebook.com/uk
 * Main reason for this being different than actual locale is the UK version,
 * which has "en" language but the url is "/uk".
 *
 * @deprecated TODO: [i18n] [bb-global] Use Gazetteer instead.
 */
export enum RoutingLocales {
  DE = 'de',
  UK = 'uk',
  FR = 'fr',
  IE = 'ie',
}

export enum EnquiryWidgetLocales {
  DE = 'de',
  FR = 'fr',
  GB = 'en-GB',
  IE = 'en-IE',
}

/**
 * These locales are used with some exiting Web APIs like Intl.NumberFormat
 *
 * They are also used for storing the user locale in Firestore in web, and they
 * match the Crowdin files names
 * @see https://www.techonthenet.com/js/language_tags.php
 *
 * @deprecated TODO: [i18n] [bb-global] This is not a scalable solution. Use Gazetteer instead.
 */
export enum BCP47LanguageTags {
  US = 'en-US',
  DE = 'de-DE',
  GB = 'en-GB',
  FR = 'fr-FR',
  IE = 'en-IE',
}

/**
 * This is used when interacting with Web APIs that depend on locale (e.g.
 * Intl.NumberFormat) which is different that the one used for routing
 *
 * @deprecated TODO: [i18n] [bb-global] This is not a scalable solution. Use Gazetteer instead.
 */
export const routingLocaleToLanguageTag: Record<RoutingLocales, BCP47LanguageTags> = {
  [RoutingLocales.DE]: BCP47LanguageTags.DE,
  [RoutingLocales.FR]: BCP47LanguageTags.FR,
  [RoutingLocales.UK]: BCP47LanguageTags.GB,
  [RoutingLocales.IE]: BCP47LanguageTags.IE,
};

/**
 * @deprecated TODO: [i18n] [bb-global] This is not a scalable solution. Use Gazetteer instead.
 */
export const routingLocaleToCountryCode: Record<RoutingLocales, CountryCodes> = {
  [RoutingLocales.DE]: CountryCodes.DE,
  [RoutingLocales.FR]: CountryCodes.FR,
  [RoutingLocales.UK]: CountryCodes.GB,
  [RoutingLocales.IE]: CountryCodes.IE,
};

/**
 * @deprecated TODO: [i18n] [bb-global] This is not a scalable solution. Use Gazetteer instead.
 */
export const countryCodeToRoutingLocale = Object.entries(routingLocaleToCountryCode).reduce(
  (acc, [key, value]) => {
    acc[value as keyof typeof acc] = key as RoutingLocales;
    return acc;
  },
  {} as Record<CountryCodes, RoutingLocales>,
);

export const routingCountryCodes = Object.values(routingLocaleToCountryCode);

export const userLocaleToLanguages = Object.entries(routingLocaleToLanguageTag).reduce(
  (acc, [key, value]) => {
    acc[value as keyof typeof acc] = key as RoutingLocales;
    return acc;
  },
  {} as Record<BCP47LanguageTags, RoutingLocales>,
);

/**
 * @deprecated TODO: [i18n] [bb-global] This is not a scalable solution.
 */
export const userLocaleToDateFnsLocale: Record<RoutingLocales, Locale> = {
  [RoutingLocales.UK]: enGB,
  [RoutingLocales.DE]: de,
  [RoutingLocales.FR]: fr,
  [RoutingLocales.IE]: enIE,
};

/** For cases where we have to fallback to a default currency */
export const fallbackCurrency = 'GBP';
